<template>
<v-container v-if="user.email == 'rene@wastics.eu'">
  <v-card>
    <v-card-title>{{test}} {{user.firstname}}</v-card-title>
  </v-card>
  <v-card class="mt-5">
    <v-card-text>
      <v-row>
        <v-col cols="9" @click="taskDialog = true">
          <h3 class="mb-2">Entsorgung Bohrschlämme</h3>
          <v-avatar color="indigo" class="white--text mr-2" size="28">RH</v-avatar>
          <v-chip>A322345</v-chip>
        </v-col>
        <v-col cols="3" class="text-right">
          <v-btn icon x-large color="primary" outlined><v-icon>mdi-play</v-icon></v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
  <v-card class="mt-5" @click="reportDialog = true">
    <v-card-title>Zum Tagesbericht</v-card-title>
    <v-card-text>
      <v-row>
        <v-col>
          Im Tagesbericht siehst du deine erfassten Zeiten und Aufgaben
        </v-col>
        <v-col class="text-right">
          <v-btn icon x-large color="primary" outlined><v-icon>mdi-file-chart-outline</v-icon></v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
  <v-dialog v-model="taskDialog" fullscreen style="z-index: 10003">
    <v-card>
      <v-card-title>Entsorgung Bohrschlämme</v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            Hier ist die Beschreibung. Auftrag A322345.
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-file-input label="Datei hochladen" outlined></v-file-input>
          </v-col>
        </v-row>
        <v-btn rounded outlined @click="timeDialog = true">Zeit hinzufügen</v-btn>
        <v-timeline align-top dense>
          <v-timeline-item small>
            <v-row class="pt-1">
              <v-col cols="3">
                <strong>03.01.2022</strong><br>
                9:45 - 10:55
              </v-col>
              <v-col>
                <strong>Planung</strong>
                <div class="text-caption">
                  Lieferplanung A322345
                </div>
              </v-col>
            </v-row>
          </v-timeline-item>
          <v-timeline-item small>
            <v-row class="pt-1">
              <v-col cols="3">
                <strong>02.01.2022</strong><br>
                13:31 - 13:47
              </v-col>
              <v-col>
                <strong>Organisation</strong>
                <div class="text-caption">
                  Kundentelefonat A322345
                </div>
              </v-col>
            </v-row>
          </v-timeline-item>
        </v-timeline>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined rounded @click="taskDialog = false">Zurück</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-dialog v-model="timeDialog" style="z-index: 10900">
    <v-card>
      <v-card-title>Zeitbuchung hinzufügen</v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="3">
            <v-text-field label="Datum" outlined></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field label="Start" outlined></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field label="Ende" outlined></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-select label="Art" outlined :items="workTypes"></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field label="Kommentar" outlined></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined rounded @click="timeDialog = false">Speichern</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-dialog v-model="reportDialog" fullscreen style="z-index: 10500">
    <v-card>
      <v-card-title>Tagesbericht</v-card-title>
      <v-card-text>
        <v-menu
            v-model="menu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="date"
                label="Picker without buttons"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                outlined
            ></v-text-field>
          </template>
          <v-date-picker
              v-model="date"
              @input="menu = false"
              no-title
              scrollable
          ></v-date-picker>
        </v-menu>
        <h3>Meine erfassten Zeiten</h3>
        <v-row>
          <v-col cols="2"><strong>Start</strong></v-col>
          <v-col cols="2"><strong>Ende</strong></v-col>
          <v-col cols="6"><strong>Beschreibung</strong></v-col>
          <v-col cols="2"><strong>Aktionen</strong></v-col>
        </v-row>
        <v-row>
          <v-col cols="2">9:00</v-col>
          <v-col cols="2">10:33</v-col>
          <v-col cols="6">Tankwagenreinigung A342711</v-col>
          <v-col cols="2">
            <v-icon>mdi-pencil</v-icon>
            <v-icon>mdi-delete</v-icon>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2">10:40</v-col>
          <v-col cols="2">11:58</v-col>
          <v-col cols="6">Tankwagenreinigung A342628</v-col>
          <v-col cols="2">
            <v-icon>mdi-pencil</v-icon>
            <v-icon>mdi-delete</v-icon>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn rounded outlined @click="timeDialog = true">Zeit hinzufügen</v-btn>
      </v-card-actions>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined rounded @click="reportDialog = false">Speichern</v-btn>
      </v-card-actions>
      <v-card-text>
        <v-card class="mt-5">
          <v-card-text>
            <h3>Meine Tagesberichte</h3>
            <v-row>
              <v-col cols="2"><strong>Datum</strong></v-col>
              <v-col cols="4"><strong>Status</strong></v-col>
              <v-col cols="2"><strong>Aktionen</strong></v-col>
            </v-row>
            <v-row>
              <v-col cols="2">02.01.2023</v-col>
              <v-col cols="4"><v-chip color="yellow">In Bearbeitung</v-chip></v-col>
              <v-col cols="2">
                <v-icon>mdi-pencil</v-icon>
                <v-icon>mdi-delete</v-icon>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="2">01.01.2023</v-col>
              <v-col cols="4"><v-chip color="green">Abgeschlossen</v-chip></v-col>
              <v-col cols="2">
                <v-icon>mdi-pencil</v-icon>
                <v-icon>mdi-delete</v-icon>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-card-text>
    </v-card>
  </v-dialog>
</v-container>
</template>

<script>
import {mapState} from "pinia";
import {wasticsStore} from "@/store";

export default {
  name: "Timesheet",
  data: () => ({
    test: "Hello",
    menu: false,
    date: "2023-01-03",
    reportDialog: false,
    taskDialog: false,
    timeDialog: false,
    workTypes: [
        "Durchführung",
        "Organisation",
        "Management",
        "Planung",
        "Qualitätssicherung",
    ],
  }),
  computed: {
    ...mapState(wasticsStore, ["keycloak"]),
    ...mapState(wasticsStore, ["user"]),
  },
}
</script>

<style scoped>

</style>