<template>
<v-container>
  <v-card>
    <v-card-text>
      <v-row>
        <v-col>
          <v-icon>mdi-filter</v-icon>
        </v-col>
        <v-col>
          <v-select label="Rolle" outlined></v-select>
        </v-col>
        <v-col>
          <v-text-field label="Suche" prepend-inner-icon="mdi-magnify" outlined></v-text-field>
        </v-col>
      </v-row>
      <v-expansion-panels>
        <v-expansion-panel>
          <v-expansion-panel-header>Saubermacher<br>Waste Collector | Waste Disposal</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-card>
              <v-card-text>
                <v-text-field label="GLN" outlined></v-text-field>
                <v-text-field label="UID" outlined></v-text-field>
                <v-text-field label="FN" outlined></v-text-field>
              </v-card-text>
              <v-card-actions>
                <v-btn>Verifizieren</v-btn>
                <v-btn>Sperren</v-btn>
                <v-btn icon><v-icon>mdi-email</v-icon></v-btn>
              </v-card-actions>
            </v-card>
            <v-card class="mt-5">
              <v-card-title>Benutzer History</v-card-title>
              <v-card-text>
                26.12.2022 - Registrierung
              </v-card-text>
            </v-card>
            <v-card class="mt-5">
              <v-card-title>Benutzer</v-card-title>
              <v-card-text>
                Name - Mail - Telefon
              </v-card-text>
            </v-card>
            <v-card class="mt-5">
              <v-card-title>Features</v-card-title>
              <v-card-text>
                <v-expansion-panels>
                  <v-expansion-panel>
                    <v-expansion-panel-header>Angebote legen (aktiv seit 22.12.2022)</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-select label="Abo" :items="subscriptions" outlined></v-select>
                      <v-text-field label="Leads" outlined></v-text-field>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel>
                    <v-expansion-panel-header>AWK</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-select label="Abo" :items="subscriptions" outlined></v-select>
                      <v-text-field label="Kontingent" outlined></v-text-field>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-card-text>
            </v-card>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card-text>
  </v-card>

</v-container>
</template>

<script>
export default {
  name: "CompanyManager",
  data: () => ({
    subscriptions: [
        "Kein Abo",
        "Free Tier",
        "Basic",
        "Expert",
        "Premium"
    ],
  }),
}
</script>

<style scoped>

</style>