<template>
<v-container style="max-width: 800px;">
  <h1>Kostenlose Registrierung</h1>
  <v-form
      ref="registrationForm"
      v-model="validRegistrationForm"
  >
    <v-card class="mt-5 pb-5">
      <v-card-title></v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="6">
            <v-text-field
                label="Firma *"
                v-model="registrationData.company"
                :rules="[rules.required]"
                outlined
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
                label="UID"
                v-model="registrationData.vatCode"
                outlined
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
                label="Vorname *"
                v-model="registrationData.firstname"
                :rules="[rules.required]"
                outlined
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
                label="Nachname *"
                v-model="registrationData.lastname"
                :rules="[rules.required]"
                outlined
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
                label="Telefon *"
                v-model="registrationData.phone"
                :rules="[rules.required]"
                outlined
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
                label="Email *"
                v-model="registrationData.email"
                :rules="[rules.required, rules.email]"
                outlined
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
                label="Passwort *"
                v-model="registrationData.password"
                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show1 ? 'text' : 'password'"
                @click:append="show1 = !show1"
                :rules="[rules.required, rules.minLength]"
                outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <div>
          * Pflichtfeld
        </div>
        <v-row class="mt-5">
          <v-col>
            <p style="font-size: 1rem; font-weight: 500;">Ich möchte:</p>
            <v-checkbox
                v-model="registrationData.waste_creator"
                label="Abfälle entsorgen (Anfragen erstellen)"
            ></v-checkbox>
            <v-checkbox
                v-model="registrationData.waste_collector"
                label="Abfälle sammeln und behandlen (Angebote legen)"
            ></v-checkbox>
          </v-col>
        </v-row>
        <v-divider class="mb-4"></v-divider>
        <v-sheet>
          Mit der Registrierung bestätige ich die Datenverarbeitung durch Wastics gemäß AGB und Datenschutzerklärung.<br>Die Registrierung ist kostenlos.
        </v-sheet>
        <v-switch label="akzeptieren" v-model="accept"></v-switch>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <span style="margin-right: 10px; color: red;">{{message}}</span>
        <v-btn rounded :disabled="!validRegistrationForm" @click="register">Registrieren</v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</v-container>
</template>

<script>
import axios from "axios";

export default {
  name: "Registration",
  data: () => ({
    accept: false,
    validRegistrationForm: false,
    show1: false,
    message: "",
    passwordConfirmation: '',
    registrationData: {
      firstname: '',
      lastname: '',
      phone: '',
      email: '',
      company: '',
      vatCode: '',
      password: '',
      waste_creator: true,
      waste_collector: false,
    },
    rules: {
      required: value => !!value || 'Erforderlich',
      counter: value => value.length <= 20 || 'Maximal 20 Zeichen',
      minLength: value => value.length > 7 || 'Mindestens 8 Zeichen',
      zip: value => value.length <= 5 || 'PLZ maximal 5-stellig',
      zip2: value => {
        const pattern = /^[0-9]{4,5}$/
        return pattern.test(value) || 'PLZ darf nur 4-5 Ziffern enthalten'
      },
      email: value => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return pattern.test(value) || 'Email-Adresse erforderlich'
      },
    },
    types: ["Abfallerzeuger", "Entsorgungspartner"],
  }),
  computed: {
    baseUrl() {
      if (window.location.hostname === "localhost") {
        return "http://localhost:30303";
      } else {
        return "";
      }
    },
  },
  methods: {
    register() {
      axios.post(this.baseUrl + "/v1/register", this.registrationData)
      .then(()=>{
        this.message = "Registrierung erfolgreich";
        setTimeout(()=>{this.$router.push("/login");}, 1000);
      })
      .catch((err) => {
        console.log(err);
        this.message = "Fehler bei der Registrierung."
      });
    },
  },
}
</script>

<style scoped>

</style>