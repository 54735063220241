<template>
<v-container v-if="user.email.endsWith('@wastics.eu')">
  <v-card>
    <v-card-title>Wastics Email System</v-card-title>
    <v-card-text>
      <v-form>
        <v-autocomplete
            label="Empfänger"
            v-model="recipient"
            :items="users"
            item-text="email"
            item-value="id"
            outlined
        >
          <template v-slot:item="data">
            <template v-if="typeof data.item !== 'object'">
              <v-list-item-content v-text="data.item"></v-list-item-content>
            </template>
            <template v-else>
              <!--<v-list-item-avatar>
                <img :src="data.item.avatar">
              </v-list-item-avatar>-->
              <v-list-item-content>
                <v-list-item-title>{{data.item.firstname}} {{data.item.lastname}}</v-list-item-title>
                <v-list-item-subtitle v-html="data.item.email"></v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </template>
        </v-autocomplete>
        <v-text-field label="Betreff" outlined v-model="subject"></v-text-field>
        <v-textarea label="Nachricht" outlined v-model="message"></v-textarea>
      </v-form>
    </v-card-text>
    <v-card-actions>
      {{errMessage}}
      <v-spacer></v-spacer>
      <v-btn color="primary" :loading="loading" :disabled="loading" @click="sendMail">Senden</v-btn>
    </v-card-actions>
  </v-card>
</v-container>
</template>

<script>
import {mapState} from "pinia";
import {wasticsStore} from "@/store";
import axios from "axios";

export default {
  name: "Mail",
  data: () => ({
    test: false,
    subject: "",
    message: "",
    recipient: "",
    loading: false,
    errMessage: "",
  }),
  computed: {
    baseUrl () {
      if(window.location.hostname === "localhost") {
        return "http://localhost:30303";
      } else {
        return "";
      }
    },
    ...mapState(wasticsStore, ["keycloak"]),
    ...mapState(wasticsStore, ["user"]),
    ...mapState(wasticsStore, ["users"]),
  },
  methods: {
    sendMail() {
      this.loading = true;
      this.errMessage = "";
      axios.post(this.baseUrl + "/sendmail2",{
        recipient: this.recipient,
        subject: this.subject,
        message: this.message,
      }).then(() => {
        this.loading = false;
        this.errMessage = "Erfolgreich gesendet."
      })
      .catch((err) => {
        this.loading = false;
        this.errMessage = err.message
        if (err.response.data) {
          this.errMessage = this.errMessage + "; " + err.response.data;
        }
      });
    },
  },
}
</script>

<style scoped>

</style>